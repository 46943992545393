
            @import 'src/styles/mixins.scss';
        
@import "@styles/sassVariables";
.selling-point {
    font-size: 11px;
    color: #999;
    padding-top: 10px;
    font-style: italic;
}
.main-banner-section {
    height: auto;
    min-height: calc(100vh - 58px);
}
.main-banner {
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: center;
    @include lg {
        flex-direction: row;
        justify-content: space-between;
    }

    .form {
        z-index: 5;
        width: 100%;
        padding: 30px 5px;
        max-width: 700px;
        border-radius: 10px;
        min-height: 500px;
        background-color: white;
        margin-top: 20px;
        display: flex;
        flex-direction: column;
        margin-bottom: 20px;
        position: relative;
        border-radius: 15px;

        @include lg {
            min-width: 300px;
            width: 46%;
            padding: 30px 8px;
            margin-top: 50px;
        }

        .steps-inner {
            flex-grow: 1;
            padding-bottom: 20px;
        }

        .step-title {
            text-align: left;
            font-size: 24px !important;
            line-height: 36px !important;
            font-weight: 700 !important;
            margin-bottom: 20px;
            color: #00445f;
            @include lg {
                line-height: 36px !important;
                font-size: 30px !important;
            }
        }
        .label-as-step-title {
            text-align: center;
            font-size: 20px !important;
            font-weight: 500 !important;
            margin-top: -20px;
            margin-bottom: 25px !important;
            @include lg {
                margin-right: -50px;
                margin-left: -50px;
            }
        }
        .step-description {
            text-align: left;
            font-weight: 400;
            margin-bottom: 50px;
            line-height: 21px;
            font-size: 16px;
            color: #00445f;
            @include lg {
                font-size: 18px;
                line-height: 26px;
            }
        }
        .fields-wrapper {
            margin-top: 1.5rem;
        }
        .form-button {
            max-width: unset !important;
            margin: 0 auto !important;
            margin-top: 5px !important;
            font-weight: bold;
            background-color: #f57f26 !important;
            transition: all 0.2s ease-in-out;
            border-radius: 8px !important;
            color: white !important;

            width: 98%;

            @apply shadow-lg;

            @include lg {
            }
            path {
                fill: white;
            }
            &:hover {
                @apply shadow;
            }
        }

        .form-field {
            width: 98%;

            max-width: unset;
            margin: 0 auto;
            margin-bottom: 0.5rem;

            label {
                font-weight: bold;
                color: #00445f;
                font-size: 16px;
                line-height: 26px;
                margin-bottom: 15px;
                @include lg {
                    font-size: 18px;
                    line-height: 26px;
                }
            }
        }
        .radio-field {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;

            margin-top: 10px;
            & > div {
                width: 100%;
                margin-bottom: 15px;
                display: flex;
                input {
                    &:checked + label {
                        font-weight: 500;
                        background-color: rgba(
                            $nextautowarranty-denim-blue,
                            0.1
                        );
                    }
                }
                label {
                    border-radius: 3px;

                    border: 1px solid $nextautowarranty-denim-blue;

                    color: $nextautowarranty-denim-blue;
                    font-size: 14px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex-direction: column;

                    @include md {
                        min-height: 64px;
                    }
                    svg {
                        margin-bottom: 10px;
                        width: 30px;
                        height: auto;
                    }
                }
                @include lg {
                    margin: 0 10px;
                    margin-bottom: 15px;
                    width: 45%;
                }
            }
            @include lg {
                margin: 0 -10px;
            }
        }
        .checkbox-field {
            & > div {
                width: 100%;
                margin-bottom: 15px;
                display: flex;
                input {
                    &:checked + label {
                        font-weight: 500;
                        background-color: rgba(
                            $nextautowarranty-denim-blue,
                            0.1
                        );
                    }
                }
                label {
                    border-radius: 3px;
                    border: 1px solid $nextautowarranty-denim-blue;
                    color: $nextautowarranty-denim-blue;
                    font-size: 14px;
                    display: flex;
                    align-items: center;

                    @include md {
                        min-height: 50px;
                    }
                }
            }
        }
        .field-note {
            margin-left: 7px;
        }
        .field-error-message {
            left: 7px;
        }
        .field {
            &:not(.radio-field) {
                border-radius: 8px;
                background-color: white;
            }
        }
        .tcpa {
            margin-bottom: 10px;
        }
    }
    .image {
        z-index: 2;
        & > div {
            position: absolute !important;
            left: 0;
            bottom: 0;
            height: 300px;
            width: 100vw;
            max-width: unset !important;
            left: 50% !important;
            transform: translateX(-50%) !important;

            @include lg {
                height: 100%;
                top: 0 !important;
                left: 0%;
            }
        }

        img {
            min-height: unset !important;
            min-width: unset !important;
            width: auto !important;
            height: 100% !important;
            max-width: unset !important;
            margin-left: 0 !important;
            margin-right: auto !important;
        }
    }

    &::before {
        content: "";
        left: 50%;
        width: 100vw;
        transform: translateX(-50%);
        height: 100%;
        position: absolute;
        background-color: white;

        z-index: -10;
    }
}
.step:nth-of-type(1) {
    .form-button,
    .form-field {
        animation: shake 1s 2.5s;
    }
}
.animationCar {
    position: absolute;
    width: 80px;
    height: 80px;
    left: 38%;
    top: 47%;
    @include sm {
        width: 110px;
        height: 110px;
        left: 40%;
        top: 50%;
    }
    @include md {
        width: 120px;
        height: 120px;
        left: 40%;
        top: 50%;
    }
    @include lg {
        width: 160px;
        height: 160px;
        left: 37%;
        top: 45%;
    }
    animation: animation 1s 0.5s;
    animation-fill-mode: forwards;
}
@keyframes animation {
    from {
    }
    to {
        transform: scale(2.2, 2.2);
    }
}
@keyframes shake {
    10% {
        transform: translateX(1px);
    }
    20% {
        transform: translateX(-2px);
    }
    30% {
        transform: translateX(3px);
    }
    40% {
        transform: translateX(-4px);
    }
    50% {
        transform: translateX(5px);
    }
    60% {
        transform: translateX(-6px);
    }
    70% {
        transform: translateX(7px);
    }
    80% {
        transform: translateX(-8px);
    }
    90% {
        transform: translateX(9px);
    }
    100% {
        transform: translateX(-10px);
    }
}

.offerContainer {
    margin-top: 10px;
}
